<template>
  <div class="columns mt-6 mx-6 is-centered">
    <div class="column is-6">
      <form class="mt-6" v-on:keyup.enter="signin">

         <h1 class="is-title is-size-3">Log In User</h1>
        <div class="field">
          <label class="label is-pulled-left is-title">Email</label>
          <div class="control">
            <input
              class="input is-text"
              type="email"
              placeholder="e.g. alex@example.com"
              v-model="email"
            />
          </div>
        </div>

        <div class="field">
          <label class="label is-pulled-left is-title">Password</label>
          <div class="control">
            <input class="input" type="password" placeholder="********" v-model="password"/>
          </div>
        </div>
        <div class="columns">
            <p class="column">
             <button class="button is-primary is-sharp is-text" type="button" v-on:click="signin">Sign in</button>
            </p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
          email:"",
          password: ""
        }
    },
    methods:{
        signup(){
          let data = {
            user:{
              email: this.email,
              password: this.password
            }
          }
            this.$store.dispatch("createUser", data).then(()=>{
              this.$store.dispatch("login", data).then(()=>{
                this.$router.push('/admin')
              })
            })
             
        },
        signin(){
          let data = {
            user:{
              email: this.email,
              password: this.password
            }
          }
          this.$store.dispatch("login", data).then(()=>{
            this.$router.push('/admin')
          })
          .catch(error =>{
            console.log(error)
          })
        },
        signout(){
          this.$store.dispatch("logout")
        }
    }
};
</script>

